import React, { useContext } from 'react';
import { Link, graphql, navigate } from 'gatsby';
import cx from 'classnames';

import {
  CartContext,
  useAddItemToCart,
  useToggleCart,
  quantities,
} from '../../contexts/CartContext';

import Spinner from '../../icons/Spinner';
import styles from './styles.module.scss';

const ProductCard = ({
  title,
  descriptionHtml,
  storefrontId,
  shopifyId,
  variants,
  handle,
  priceRangeV2,
}) => {
  const {
    cart: { isAdding, currentLineItem },
  } = useContext(CartContext);

  const toggleCart = useToggleCart();
  const addItemToCart = useAddItemToCart();
  const variant = variants[0];
  const quantity = quantities[variant.sku.toUpperCase()];
  // const price =
  //   variant.price % 1 === 0 ? variant.price.split('.')[0] : variant.price;

  const price = priceRangeV2.maxVariantPrice.amount;

  async function handleAddToCart() {
    await addItemToCart(variant.storefrontId, 1);
    toggleCart(true);
  }
  return (
    <div className={styles.base}>
      <h3 className={styles.title}>{title}</h3>
      <h4 className={styles.price}>
        ${Math.round(price / quantity)}{' '}
        {quantity > 1 && <small>per&nbsp;box</small>}
      </h4>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
      />
      {/* <select>
        {variants.map(option => (
          <option key={option.shopifyId}>{option.title}</option>
        ))}
      </select>
      <Link to={`/product/${handle}`}>{title}</Link> */}

      <button className={cx(styles.select, 'btn')} onClick={handleAddToCart}>
        {isAdding && variant.storefrontId === currentLineItem && (
          <Spinner className={styles.spinner} />
        )}{' '}
        Add to cart
      </button>
    </div>
  );
};

export default ProductCard;
